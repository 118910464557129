.student {
    .details {
        .info {
            table {
                td {
                    .actions {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around !important;
                        max-width: 65px !important;
                        margin: 0 !important;
                    }
                }
            }

            display: flex;
            flex-direction: column;
            line-height: 22px;

            .boxes {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .box {
                    flex-basis: 48.5%;

                    .photo {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 20px;

                        img {
                            width: 200px;
                        }
                    }
                }
            }

            .header {
                padding: 20px 0;
                margin: 0;
                border: 0;
                display: block;

                h4 {
                    border: 0;
                    text-align: center;
                }

                .buttons {
                    justify-content: center;

                    button {
                        border: 0;
                        color: #fff;
                        background-color: var(--bg-dark);
                    }
                }
            }
        }
    }
}