.students-in-session {
  .add-assessment {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    .header {
      h4 {
        border: 0;
      }
      .buttons {
        img {
          width: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
