:root {
    --bg-dark: #3991DE;
    --bg-darker: #4e73df;
    --text-light: #fff;
    --bg-main: #F2F6FF;
}

* {
    margin: 0;
}

body {
    font-size: 16px;
    font-family: DM Sans, sans-serif;
    color: #4c4c4c;
}

h1,
h2,
h3,
h4 {
    font-family: Rubik;
}