.dashboard {
    .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        .button {
            color: #37474f;
            transition: all 300ms ease-in-out;
            cursor: pointer;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 15px;
            width: 100%;
            margin: 15px;
            h4 {
                color: #fff;
            }
            >div:first-child>img {
                width: 32px;
                height: 32px;
                margin: auto;
                display: block;
            }
            >div:first-child {
                padding: 15px;
                border-radius: 15px 0 0 15px;
            }
            &.applications {
                background-color: #1cc88a;
            }
            &.points {
                background-color: #d98d37;
            }
            &.payments {
                background-color: #e74a3b;
            }
            &.membership {
                background-color: #36b9cc;
            }
            &.videos {
                background-color: #f6c23e;
            }
            &.program {
                background-color: #4e73df;
            }
        }
        .button:first-child {
            background-color: #36b9cc;
        }
    }
    @media (min-width: 1000px) {
        .buttons {
            .button {
                max-width: 270px;
            }
        }
    }
}